import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useRef,
} from "react";
import { useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TemaService from "../../services/TemaService";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import CampanhaService from "../../services/CampanhaService";
import LoaderService from "../../services/LoaderService";
import AcerteTempoService from "../../services/AcerteTempoService";

const AcerteTempoContext = createContext();

export const AcerteTempoProvider = ({ children }) => {
  const TipoComponenteEnum = Object.freeze({
    apresentacao: "APRESENTACAO",
    comoFunciona: "COMO_FUNCIONA",
    atividadeIniciada: "ATIVIDADE_INICIADA",
    placar: "PLACAR",
    modoRevisao: "REVISAO_HC",
  });

  const acerteTempoService = new AcerteTempoService();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const loaderService = new LoaderService();
  const campanhaService = new CampanhaService();

  const navigate = useNavigate();
  let { codigo, codigoAtividade } = useParams();
  const i18n = useStore().getState().i18n;

  const [erro, setErro] = useState(null);
  const [acerteTempo, setAcerteTempo] = useState();
  const [alternativaUuid, setAlternativaUuid] = useState();
  const [obtemPontuacaoQuestaoHC, setObtemPontuacaoQuestaoHC] = useState(null);
  const [obtemPlacarFinal, setObtemPlacarFinal] = useState(null);

  const [indiceAcerteTempo, setIndiceAcerteTempo] = useState(0);
  const [totalDeQuestoes, setTotalDeQuestoes] = useState(0);

  const [mostrarAlternativas, setMostrarAlternativas] = useState(false);
  const [esconderProntoResponderBtn, setEsconderProntoResponderBtn] =
    useState(false);
  const [showTagPontuacao, setShowTagPontuacao] = useState(false);
  const [isContando, setIsContando] = useState(false);
  const [apagarContagem, setApagarContagem] = useState(false);
  const [allowsAnswerAfterTime, setAllowsAnswerAfterTime] = useState(false);

  const [componentePagina, setComponentePagina] = useState();

  const [campaignType, setCampaignType] = useState();

  const effectRan = useRef(false);

  useEffect(() => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      setCampaignType(campanhaService.campanhaCorrente().campaignType);
      iniciarAtividade();
      let service = new TemaService(campanhaService.campanhaCorrente());
      service.aplicarTema("HOME");
    }

    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (componentePagina === TipoComponenteEnum.placar) {
      iniciarAtividade();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentePagina]);

  const setaAcerteTempoIndice = (index, origem) => {
    acerteTempoService.setIndiceLocalStorage(index, acerteTempo?.uuid, origem);
  };

  const iniciarAtividade = async () => {
    try {
      const atividade = await atividadesService.iniciarAtividadePromisse(
        codigo,
        codigoAtividade,
      );
      if (atividade.dateFinished !== null) {
        await atualizarAgendaPromisse();
        setObtemPlacarFinal(atividade?.rightHabit?.scoreboard);
        mudarComponentePagina(TipoComponenteEnum.placar);
        acerteTempoService.removeIndiceLocalStorage(
          atividade?.rightHabit?.uuid,
        );
      } else {
        mudarComponentePagina(TipoComponenteEnum.comoFunciona);
      }

      /*
        Procura no localstorage onde o participnate parou, se não encotrar
        pega do endpoint a questão remanscente se houver.
      */
      const indice = acerteTempoService.getIndiceLocalStorage(
        atividade?.rightHabit?.uuid,
      );
      if (indice !== null) {
        mudarComponentePagina(TipoComponenteEnum.atividadeIniciada);
        setIndiceAcerteTempo(indice);
        const origem = acerteTempoService.getOrigemLocalStorage(
          atividade?.rightHabit?.uuid,
        );
        if (origem === "QUESTAO_ABERTA") {
          setMostrarAlternativas(true);
          setEsconderProntoResponderBtn(true);
        }
      } else {
        if (atividade?.rightHabit?.remainingQ !== null) {
          const indiceProximaQuestao =
            atividade?.rightHabit?.questions.findIndex(
              (q) => q.uuid === atividade?.rightHabit?.remainingQ,
            );
          if (indiceProximaQuestao !== -1) {
            mudarComponentePagina(TipoComponenteEnum.atividadeIniciada);
            setIndiceAcerteTempo(indiceProximaQuestao);
          }
        }
      }

      setAcerteTempo(atividade?.rightHabit);
      setTotalDeQuestoes(atividade?.rightHabit?.questions.length);
      setAllowsAnswerAfterTime(atividade?.rightHabit?.allowsAnswerAfterTime);
      if (atividade?.rightHabit?.questions?.length > 1) {
        loaderService.load(atividade?.rightHabit?.questions[1]?.image);
      }
    } catch (erro) {
      if (erro) {
        if (erro.response && erro.response.data && erro.response.data.message) {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.iniciar.titulo",
              "Erro ao iniciar atividade",
            ),
            mensagem: erro.response.data.message,
          });
        } else {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.iniciar.titulo",
              "Erro ao iniciar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.erro.iniciar.mensagem",
              "Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
          });
        }
      }
    }
  };

  const atualizarAgendaPromisse = () => {
    return new Promise((resolve, reject) => {
      agendaService.atualizarAgenda(
        codigo,
        codigoAtividade,
        "RIGHT_TIME",
        null,
        null,
        (erro, sucesso) => {
          if (erro) {
            setErro({
              titulo: i18n.message(
                "dinamica.erro.jaexecutada.titulo",
                "Erro ao exibir atividade já executada",
              ),
              mensagem: i18n.message(
                "dinamica.erro.jaexecutada.mensagem",
                "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
              ),
            });
            reject(erro);
          } else {
            resolve(sucesso);
          }
        },
      );
    });
  };

  const respondeAcerteTempoEndpoint = (
    alternativaArr,
    questaoUuidCorrente,
    tempoAcabou,
  ) => {
    const request = {
      activity: codigoAtividade,
      question: questaoUuidCorrente,
      alternatives: alternativaArr,
      timeIsOver: tempoAcabou,
    };
    return responderQuestao(request);
  };

  const responderQuestao = (request) => {
    return new Promise((resolve, reject) => {
      acerteTempoService
        .responderQuestaoHC(request)
        .then((sucesso) => {
          if (
            request?.alternatives?.length > 0 ||
            request?.timeIsOver === true
          ) {
            setObtemPontuacaoQuestaoHC(sucesso);
            /*
            Quando o placar aparece significa que todas as
            questões foram respondidas e finalizadas.
          */
            if (
              sucesso?.scoreboard !== null &&
              sucesso?.scoreboard !== undefined
            ) {
              setObtemPlacarFinal(sucesso?.scoreboard);
              atualizarAgendaPromisse();
              acerteTempoService.removeIndiceLocalStorage(acerteTempo?.uuid);
            }
          }
          resolve(sucesso);
        })
        .catch((erro) => {
          if (erro) {
            if (erro.response && erro.response.status === 409) {
              if (erro?.response?.data?.question) {
                getIndexByUuidAndSetNextQuestion(
                  erro?.response?.data?.question,
                  acerteTempo?.questions,
                );
              }
              resolve();
              return;
            }
            if (erro?.response?.data?.message) {
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.iniciar.titulo",
                  "Erro ao iniciar atividade",
                ),
                mensagem: erro.response.data.message,
              });
              reject(erro);
              return;
            }
            setErro({
              titulo: i18n.message(
                "dinamica.erro.iniciar.titulo",
                "Erro ao iniciar atividade",
              ),
              mensagem: i18n.message(
                "dinamica.erro.iniciar.mensagem",
                "Entre em contato com o suporte ou tente novamente mais tarde.",
              ),
            });
            reject(erro);
          }
        });
    });
  };

  /* Pega o index pelo uuid retornado pelo endpoint e seta a proxima
  questão a ser respondida do Acerte em tempo */
  const getIndexByUuidAndSetNextQuestion = (uuid, questoes) => {
    const indiceProximaQuestao = questoes.findIndex((q) => q.uuid === uuid);
    if (indiceProximaQuestao !== -1) {
      if (
        acerteTempo?.questions !== null &&
        acerteTempo?.questions?.length > 1
      ) {
        loaderService.load(
          acerteTempo?.questions[indiceProximaQuestao]?.picture,
        );
      }
      setIndiceAcerteTempo(indiceProximaQuestao);
      setObtemPontuacaoQuestaoHC(null);
      respondeAcerteTempoEndpoint(
        [],
        acerteTempo?.questions[indiceProximaQuestao]?.uuid,
        false,
      );
    } else {
      console.error("Erro ao encontrar a questão pelo uuid");
      setErro({
        titulo: i18n.message(
          "dinamica.erro.responder.titulo",
          "Erro ao processar a resposta",
        ),
        mensagem: i18n.message(
          "dinamica.erro.responder.quertion.null",
          "Não foi possivel encontrar a proxima pergunta !",
        ),
      });
    }
  };

  const proximoAcerteTempo = () => {
    let indice = indiceAcerteTempo + 1;
    if (indice === acerteTempo?.questions?.length) {
      setIndiceAcerteTempo(0);
      mudarComponentePagina(TipoComponenteEnum.placar);
      return;
    }
    if (acerteTempo?.questions?.length > indice + 1) {
      loaderService.load(acerteTempo?.questions[indice + 1]?.image);
    }
    setIndiceAcerteTempo(indice);
    setMostrarAlternativas(false);
    setEsconderProntoResponderBtn(false);
    setShowTagPontuacao(false);
    setObtemPontuacaoQuestaoHC(null);
  };

  const proximoAcerteTempoRevisao = () => {
    let indice = indiceAcerteTempo + 1;
    if (indice === acerteTempo?.questions?.length) {
      return;
    }
    if (acerteTempo?.questions?.length > indice + 1) {
      loaderService.load(acerteTempo?.questions[indice + 1]?.image);
    }
    setIndiceAcerteTempo(indice);
  };

  const anteriorAcerteTempoRevisao = () => {
    let indice = indiceAcerteTempo === 0 ? 0 : indiceAcerteTempo - 1;
    if (acerteTempo?.questions?.length > indice - 1) {
      loaderService.load(acerteTempo?.questions[indice - 1]?.image);
    }
    setIndiceAcerteTempo(indice);
  };

  const revisaoMostrarTagPontos = () => {
    setShowTagPontuacao(true);
  };

  const respondeQuestao = async (alternativaUuid) => {
    /*
      Agregadora: #16025
      No momento desse desenvolvimento 16/11/23, a array de alternativas deve ter apenas uma selecionada
      a cada nova questão respondida. Por isso, não estamos usando o spread operator para adicionar
      um novo uuid ao final da array.

      E também é por isso que no componente de Alternativas está sendo usado inputs do tipo radio
      em vez de checkboxes.
    */
    setShowTagPontuacao(true);
    setAlternativaUuid(alternativaUuid);
    let alternativaArr = [];
    alternativaArr.push(alternativaUuid);
    // fecho a questão e atualizo o indice de onde parou no localStorage
    respondeAcerteTempoEndpoint(
      alternativaArr,
      acerteTempo?.questions[indiceAcerteTempo]?.uuid,
      false,
    ).then((response) => {
      setaAcerteTempoIndice(indiceAcerteTempo + 1, "QUESTAO_FECHADA");
    });
  };

  const mostraAlternativas = () => {
    setMostrarAlternativas(true);
    setEsconderProntoResponderBtn(true);
  };

  const mudarComponentePagina = (tipoPagina) => {
    setComponentePagina(tipoPagina);
  };

  const voltar = () => {
    setErro(null);
    navigate("/atividades");
  };

  const iniciarContador = () => {
    setIsContando(true);
  };

  const pararContador = () => {
    localStorage.removeItem("acerteTempo__questionTime");
    localStorage.removeItem("acerteTempo__timestamp__enterPage");
    setIsContando(false);
  };

  const resetarContador = (resetar) => {
    localStorage.removeItem("acerteTempo__questionTime");
    localStorage.removeItem("acerteTempo__timestamp__enterPage");
    setApagarContagem(resetar);
  };

  /*
    Impede o participante de responder a questão
    caso a configuração allowsAnswerAfterTime vinda
    do core esteja marcada como false
    e o tempo tenha acabado.
  */
  const impedirResposta = async () => {
    pararContador();
    setAlternativaUuid(null);
    respondeAcerteTempoEndpoint(
      [],
      acerteTempo?.questions[indiceAcerteTempo]?.uuid,
      true,
    ).then((response) => {
      setaAcerteTempoIndice(indiceAcerteTempo + 1, "QUESTAO_FECHADA");
      setShowTagPontuacao(true);
    });
  };

  const responderAcerteTempoTimeIsOver = () => {
    proximoAcerteTempo();
  };

  return (
    <AcerteTempoContext.Provider
      value={{
        erro,
        acerteTempo,
        alternativaUuid,
        obtemPontuacaoQuestaoHC,
        obtemPlacarFinal,
        indiceAcerteTempo,
        totalDeQuestoes,
        mostrarAlternativas,
        esconderProntoResponderBtn,
        showTagPontuacao,
        componentePagina,
        TipoComponenteEnum,
        isContando,
        apagarContagem,
        allowsAnswerAfterTime,
        campaignType,
        // funções
        responderAcerteTempoTimeIsOver,
        pararContador,
        iniciarContador,
        resetarContador,
        respondeAcerteTempoEndpoint,
        setaAcerteTempoIndice,
        proximoAcerteTempo,
        proximoAcerteTempoRevisao,
        anteriorAcerteTempoRevisao,
        respondeQuestao,
        mostraAlternativas,
        voltar,
        mudarComponentePagina,
        revisaoMostrarTagPontos,
        impedirResposta,
      }}
    >
      {children}
    </AcerteTempoContext.Provider>
  );
};

export const useAcerteTempo = () => {
  const context = useContext(AcerteTempoContext);
  if (!context) {
    console.log(
      "useAcerteTempo deve ser usado dentro de um AcerteTempoProvider",
    );
    throw new Error(
      "useAcerteTempo deve ser usado dentro de um AcerteTempoProvider",
    );
  }
  return context;
};
