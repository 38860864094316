import styled from "styled-components";

export const AvaliacaoDinamicaPresencialStyled = styled.div`
  .icon-star-avaliacao {
    width: 2rem;
    height: 2rem;
  }
  .weex-l-texto-customizado {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }
  .container-avaliacao {
    border-left: none !important;
  }
`;

export const PresencialStyled = styled.div`
  .container-contador {
    margin: 2rem;
  }

  .container-footer {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
`;
