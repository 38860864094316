import React from "react";

import CampanhaService from "../../../services/CampanhaService";
import CustomRegisterCampaign from "../../../comps/authInscricao/inscricao/CustomRegisterCampaign";

function RegisterSelector(props) {
  const campanhaService = new CampanhaService();

  const campanhaCorrente = campanhaService.campanhaCorrente();

  const classeDivForm = campanhaCorrente.restrictEligibility ? "" : "";

  return (
    <div>
      <div className={`${classeDivForm}`}>
        {campanhaCorrente.tipoInscricao === "CUSTOMIZADO" ? (
          <CustomRegisterCampaign
            campanha={campanhaCorrente}
            modoLogin={props.modoLogin}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RegisterSelector;
