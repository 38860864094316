import React from "react";
import { useStore } from "react-redux";
import BotaoSecundario from "../../botaoSecundario/BotaoSecundario";
import WeexModal from "../../weexModais/WeexModal";
import GerarSelectUnidadesTree from "../input/GerarSelectUnidadesTree";
function CustomRegisterCampaignMobile(props) {
  const i18n = useStore().getState().i18n;

  let link = "";
  if (props?.campanha?.privacyPolicy) {
    link = props.campanha.privacyPolicy;
  } else {
    link = "https://weex.digital/politica-de-privacidade-e-uso/";
  }

  return (
    <form onSubmit={props.registro}>
      {props.erro != null ? (
        <WeexModal
          fecharModalCallback={props.callbackWarning}
          titulo={props.erro.titulo}
          conteudo={props.erro.mensagem}
        />
      ) : null}

      {props.fields.map(function (el, index) {
        return props.renderizaCampo(el, index);
      })}

      {props.campanha.registrationUnitMandatory ? (
        <>
          <GerarSelectUnidadesTree
            campanha={props.campanha}
            i18n={i18n}
            onChange={props.onChange}
          ></GerarSelectUnidadesTree>
        </>
      ) : (
        ""
      )}

      <label
        htmlFor="acceptTerms"
        className="checkbox"
        style={{
          textAlign: "center",
          marginTop: "2.0em",
          marginBottom: "2.0em",
        }}
        aria-label={i18n.message(
          "aria.label.campo.registro.legal.termos",
          " Aceitar termos e condições ",
        )}
      >
        <input
          onChange={props.onChangeAccept}
          type="checkBox"
          name="aceito"
          id="acceptTerms"
        />
        &nbsp;&nbsp;{i18n.message("registro.legal.liaceito", "Li e aceito os")}
        <a
          className="link-sublinhado"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n.message("registro.legal.termos", " Termos e Condições ")}
        </a>
        {i18n.message("registro.legal.daweex", "da Weex")}
      </label>

      <p style={{ textAlign: "center", marginTop: "1.0em" }}>
        <BotaoSecundario
          id="register"
          isForm={true}
          disabled={props.accept || props.captcha ? false : true}
          nome={
            props.processando
              ? i18n.message("geral.aguarde", "AGUARDE...")
              : i18n.message("registro.criar", "CADASTRAR")
          }
        />
        <div className="button-cadastro-content">
          <div style={{ width: "auto" }}>
            <BotaoSecundario
              disabled={false}
              nome={i18n.message(
                "registro.possuiCadastro",
                "Já possuo cadastro",
              )}
              funcao={props.modoLogin}
              classeComplementar="botao-ja-possuo-cadastro"
            />
          </div>
        </div>
      </p>
    </form>
  );
}

export default CustomRegisterCampaignMobile;
