import styled from "styled-components";

export const CampanhaLogosStyle = styled.div`
  .foreground {
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
  }

  .foreground_not_logged {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  .campanha.logosTopo {
    width: 100vw;
    min-width: 100vw;
  }

  .campanha_logosTopo {
    text-align: center;
  }

  .campanha_logosTopo_left {
    max-width: 25vw;
    float: left;
  }

  .campanha_logosTopo_right {
    max-width: 25vw;
    float: right;
  }

  .campanha_logosTopo__centro {
    width: 100%;
  }

  .campanha_logosTopo_name {
    margin-top: 0.5em;
    /* text-align: center; */
    /* text-transform: uppercase; */
    /* font-size: 1.728em; */
    /* font-weight: 700; */
  }

  .campanha_logosTopo_subtitle {
    /* text-align: center; */
    /* font-size: 1.382em; */
    /* font-weight: 400;   */
  }

  .campanha_logosTopo_client {
    /* text-align: center; */
    /* font-size: 1.44em; */
    /* text-transform: uppercase; */
  }

  .weex-l-font-size-title__default h1,
  .weex-l-font-size-title__default h2,
  .weex-l-font-size-title__medium h1,
  .weex-l-font-size-title__medium h2,
  .weex-l-font-size-title__large h1,
  .weex-l-font-size-title__large h2 {
    text-align: center;
  }

  .weex-l-font-size-title__default h1,
  .weex-l-font-size-title__medium h1,
  .weex-l-font-size-title__large h1 {
    text-transform: uppercase;
    font-weight: 700;
  }

  .weex-l-font-size-title__default h2,
  .weex-l-font-size-title__medium h2,
  .weex-l-font-size-title__large h2 {
    font-weight: 400;
  }

  .weex-l-font-size-title__default h1 {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .weex-l-font-size-title__default h2 {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .weex-l-font-size-title__medium h1 {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }

  .weex-l-font-size-title__medium h2 {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  .weex-l-font-size-title__large h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .weex-l-font-size-title__large h2 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
`;
