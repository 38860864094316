import styled from "styled-components";

export const LoginRegisterContainerStyle = styled.div`
  width: 100%;
  min-width: 100%;

  @media screen and (min-width: 1900px) {
    margin-bottom: 2em;
    margin-top: 3em;
  }

  .margin-top-3 {
    margin-top: 3em;
  }
`;

export const WeexUserContainerStyle = styled.div`
  border: none;
  margin-top: 1em;
  padding: 1em;
  margin-bottom: 1em;
`;

export const ModoStyle = styled.ul`
  li {
    font-weight: 700;
    font-size: 1rem;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1rem;
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
`;

export const RegisterCampaignContainerStyle = styled.div`
  label {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  a,
  .button-link {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  .espacamento-inicio {
    margin-top: 1.5rem;
  }
  label&:hover {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  .button-cadastro-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .botao-ja-possuo-cadastro {
    margin-top: 3em;
    font-size: 0.7rem;
  }

  @media screen and (min-width: 700px) {
    .field-group-checkbox {
      margin-left: 1.3vw;
      margin-top: 2vh;
    }
    .cadastro-desktop {
      margin: 0 20vw 0 20vw;
    }
    form {
      align-items: center;
      justify-content: center;
    }
    .field-cadastro {
      width: 90%;
    }
    .input-size {
      width: 100%;
    }
    .sem-label {
      margin-top: 0.62em;
    }
    .senha1 {
      margin-top: 1.8em;
    }
    .margin_top_ultima_linha {
      margin-top: 0.75em;
    }
    .field-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      min-width: 25vw;
    }
    .botao-cadastro-content {
      margin: 0 7vw 0 7vw;
    }
    .inputs_forms {
      display: flex;
      justify-content: space-between;
    }
    .select-input,
    select {
      height: 4.2em;
    }
    .button-cadastro-content {
      text-align: center;
      margin-top: 1em;
      margin-left: 1vw;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .button-cadastro-content button {
      width: 70%;
    }

    .button-cadastro-content a {
      text-align: center;
    }

    .cadastro-desktop {
      display: block;
    }
    .cadastro-mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
    .inputs_forms {
      display: flex;
      justify-content: space-between;
    }
    .input-size {
      padding-top: 2em;
      padding-bottom: 2em;
      padding-left: 1em;
    }
    .checkbox-label-style {
      text-align: center;
      margin-top: 2em;
      margin-bottom: 1em;
    }

    .ultima-div:last-child {
      margin-top: 2.2rem;
    }
    .unidade:first-child {
      margin-top: -0.7rem;
    }
    .field-cadastro:last-child:not(:only-child) .field {
      margin-bottom: 1.5rem;
    }

    .field-group .field-cadastro.unidade:last-child :not(:only-child) .field {
      margin-top: 0.8rem;
    }
  }
`;

export const LoginCampaingStyle = styled.div`
  label {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  a,
  .button-link {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
    font-size: 1rem;
  }
  .espacamento-inicio {
    margin-top: 1.5rem;
  }
  label &&:hover {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  .weex-l-color {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  .weex-l-button-reenviar {
    width: 100%;
  }

  .weex-l-button-reenviar &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
`;
