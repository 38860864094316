import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import WeexMessage from "../../../comps/WeexMessage";
import WeexSpinner from "../../../comps/WeexSpinner/WeexSpinner";
import CampanhaService from "../../../services/CampanhaService";
import TemaService from "../../../services/TemaService";
import UsuarioService from "../../../services/UsuarioService";
import LoginCampaign from "../../../comps/authInscricao/login/LoginCampaign";
import {
  LoginRegisterContainerStyle,
  ModoStyle,
  WeexUserContainerStyle,
} from "../style";
import RegisterSelector from "../inscricao/RegisterSelector";
import Login2FA from "../../../comps/authInscricao/login/Login2FA";

function LoginRegister() {
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();

  const campanhaService = new CampanhaService();
  const usuarioService = new UsuarioService();
  const usuario = usuarioService.usuarioCorrente();
  const navigate = useNavigate();
  const campanhaCorrente = campanhaService?.campanhaCorrente();
  const adminOrGestor = usuarioService.isAdmin() || usuarioService.isGestor();

  useEffect(() => {
    const temaService = new TemaService(campanhaCorrente);
    temaService.aplicarTema("ENTRADA");
    if (temaService.temBackGround()) {
      dispatch({ type: "backgroundispresent", payload: "true" });
    }
    if (usuario && campanhaCorrente) {
      campanhaService.contadorCampanha((erro, sucesso) => {
        if (erro) {
          console.error(erro);
          return;
        }
        if (sucesso) {
          if (sucesso.length > 0 || adminOrGestor) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "campanhaComecou", payload: false });
            navigate("/home");
          }
        }
      });
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modo, setModo] = useState(
    campanhaService.campanhaCorrente()
      ? campanhaService.campanhaCorrente().restrictEligibility
        ? "login"
        : "register"
      : "notfound",
  );

  useEffect(() => {
    if (!campanhaCorrente) {
      navigate("/campaign");
    }
  }, [campanhaCorrente, navigate]);

  const modoLogin = () => {
    dispatch({ type: "modo", payload: "login" });
    setModo("login");
  };

  const modoRegister = () => {
    dispatch({ type: "modo", payload: "register" });
    setModo("register");
  };

  const login = () => {
    if (campanhaCorrente?.extraAuthInfo?.includes("EMAIL_2FA")) {
      return <Login2FA />;
    } else if (campanhaCorrente) {
      return (
        <LoginCampaign
          campanha={campanhaCorrente}
          modoRegister={modoRegister}
        />
      );
    }
  };

  const exporRegistro = !campanhaCorrente?.restrictEligibility;
  return (
    <LoginRegisterContainerStyle>
      {usuario ? (
        <WeexSpinner aguarde={i18n.message("geral.aguarde", "Aguarde...")} />
      ) : (
        <WeexUserContainerStyle>
          {exporRegistro ? (
            <div
              style={{
                textAlign: "center",
                marginBottom: "1.2em",
                textTransform: "capitalize",
              }}
            >
              <div>
                <ModoStyle>
                  {modo === "register" ? (
                    <li>
                      <WeexMessage
                        code="registro.action.registre"
                        default="CADASTRO"
                      />
                    </li>
                  ) : null}
                </ModoStyle>
              </div>
            </div>
          ) : null}
          {modo === "register" ? (
            <RegisterSelector
              campanha={campanhaCorrente}
              modoLogin={modoLogin}
            />
          ) : (
            <>{login()}</>
          )}
        </WeexUserContainerStyle>
      )}
    </LoginRegisterContainerStyle>
  );
}
export default LoginRegister;
