import React, { useEffect, useState } from "react";
import WeexMessage from "../../WeexMessage";

/**
 *
 * @param {Object} props
 * @param {import('../../../services/CampanhaService').Event} props.campanha
 * @param {i18n} props.i18n
 * @param {Function} props.onChange
 * @returns
 */

function GerarSelectUnidadesTree({ campanha, onChange, i18n }) {
  const [selectsUnidades, setSelectsUnidades] = useState([]);
  const [unidadeMae, setUnidadeMae] = useState(null);
  const [subareas, setSubareas] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);

  useEffect(() => {
    const inputUnidades = () => {
      if (campanha?.client?.units) {
        let unidades = campanha.client.units;
        if (unidades && unidades.length > 0) {
          return unidades;
        }
        return null;
      }
    };
    let unidades = inputUnidades();

    if (unidades) {
      setSelectsUnidades(unidades);
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputUnidade = () => {
    if (selectsUnidades && selectsUnidades.length > 0) {
      return (
        <div className="field">
          <label className="label" htmlFor="selectUnit">
            <WeexMessage
              code="registro.campo.area"
              default={i18n.message(
                "select.unidade.title",
                "Selecione sua área",
              )}
            />
            <div className="control select-margin-top-control">
              <select
                id="selectUnit"
                className="select-input input is-medium"
                value={unidadeMae}
                name="unit"
                onChange={(e) => mudarUnidade(e, 0)}
              >
                <option value="">
                  {i18n.message("select.unidade", "Selecione uma unidade")}
                </option>
                {selectsUnidades.map((opcao) => {
                  return (
                    <option value={opcao.uuid} key={opcao.uuid}>
                      {opcao.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </label>
        </div>
      );
    }
    return null;
  };

  const inputSubUnidade = () => {
    const unidade = selectsUnidades.find(
      (unidade) => unidade.uuid === unidadeMae,
    );
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }

    return (
      <div className="field">
        <label className="label mt-5" htmlFor="selectSubUnit">
          <WeexMessage
            code="registro.campo.subarea"
            default="Selecione sua subárea"
          />
          <div className="control select-margin-top-control">
            <select
              id="selectSubUnit"
              className="select-input input is-medium"
              value={subareas}
              name="subUnit"
              onChange={(e) => mudarUnidade(e, 1)}
            >
              <option value="">
                {i18n.message("select.subárea", "Selecione uma subárea")}
              </option>
              {unidade.subUnits.map((opcao) => {
                return (
                  <option value={opcao.uuid} key={opcao.uuid}>
                    {opcao.name}
                  </option>
                );
              })}
            </select>
          </div>
        </label>
      </div>
    );
  };

  const inputLevel3 = () => {
    const unidade = selectsUnidades.find(
      (unidade) => unidade.uuid === unidadeMae,
    );
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    const subUnidade = unidade.subUnits.find(
      (subUnidade) => subUnidade.uuid === subareas,
    );
    if (
      !subUnidade ||
      !subUnidade.subUnits ||
      subUnidade.subUnits.length === 0
    ) {
      return null;
    }

    return (
      <div className="field">
        <label className="label mt-5" htmlFor="nivel3">
          <WeexMessage
            code="registro.campo.nivel3"
            default="Selecione sua sub-subárea"
          />
          <div className="control select-margin-top-control">
            <select
              id="nivel3"
              className="select-input input is-medium"
              value={subSubUnit}
              name="level3"
              onChange={(e) => mudarUnidade(e, 2)}
            >
              <option value="">
                {i18n.message(
                  "select.subsubunidade",
                  "Selecione uma sub-subunidade",
                )}
              </option>
              {subUnidade.subUnits.map((opcao) => {
                return (
                  <option value={opcao.uuid} key={opcao.uuid}>
                    {opcao.name}
                  </option>
                );
              })}
            </select>
          </div>
        </label>
      </div>
    );
  };

  const mudarUnidade = (event, nivel) => {
    event.preventDefault();
    const value = event.target.value;
    switch (nivel) {
      case 0:
        setUnidadeMae(value);
        setSubareas(null);
        setSubSubUnit(null);
        onChange(event);
        break;
      case 1:
        setSubareas(value);
        setSubSubUnit(null);
        onChange(event);
        break;
      case 2:
        setSubSubUnit(value);
        onChange(event);
        break;
      default:
        console.error("Nível desconhecido");
        break;
    }
  };

  return (
    <>
      {inputUnidade()}
      {inputSubUnidade()}
      {inputLevel3()}
    </>
  );
}

export default GerarSelectUnidadesTree;
