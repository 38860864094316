import React from "react";
import UsuarioService from "../../services/UsuarioService";
import CampanhaService from "../../services/CampanhaService";

function InfosTextuais() {
  const usuarioService = new UsuarioService();
  let usuariologado =
    usuarioService.usuarioCorrente() &&
    usuarioService.usuarioCorrente().token != null
      ? true
      : false;

  const campanhaService = new CampanhaService();

  let campanhaCorrente = campanhaService.campanhaCorrente();
  let title = "";
  let campanhaName = "";
  let titleVisible;
  let loggedInAreaTitle = "";

  if (campanhaCorrente) {
    titleVisible = campanhaCorrente.titleVisible;
    title = campanhaCorrente.title;
    campanhaName = campanhaCorrente.name;
    loggedInAreaTitle = campanhaCorrente.subtitle;
  }

  return (
    <>
      {campanhaName && titleVisible && !usuariologado ? (
        <div className="campanha_logosTopo_name foreground_not_logged">
          <h1>{campanhaName}</h1>
        </div>
      ) : (
        ""
      )}

      {loggedInAreaTitle && usuariologado ? (
        <div className="campanha_logosTopo_name foreground">
          <h1>{loggedInAreaTitle}</h1>
        </div>
      ) : null}

      {title ? (
        <div
          className={
            usuariologado
              ? "campanha_logosTopo_subtitle foreground"
              : "campanha_logosTopo_subtitle foreground_not_logged"
          }
          style={{ marginTop: "10px" }}
        >
          <h2>{title}</h2>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default InfosTextuais;
